export const MAX_WIDTH = '1440px';
export const BREAKPOINTS = {
    xs: 0,
    xm: 480, // reminder to review or delete this later
    sm: 576,
    md: 753, // Changed from 768, due to Samsung Tab S9 screen resolution
    lg: 992,
    lg2: 1204,
    xl: 1440,
};

export const COLORS = {
    primary: {
        50: '#FAFAFA',
        100: '#F5F4F0',
        200: '#EAE8E3',
        300: '#DDD9D4',
        400: '#C2BFB9',
        500: '#9E9A94',
        600: '#706C66',
        700: '#484540',
        800: '#201F1C',
        900: '#121212',
        get DEFAULT() {
            return this[900];
        },
    },
    secondary: {
        50: '#F0EBFC',
        100: '#C4B1F1',
        200: '#784DD0',
        300: '#643BB5',
        400: '#4D2F82',
        500: '#2C1457',
        get DEFAULT() {
            return this[400];
        },
    },
    grayprimary: {
        100: '#F5F4F0',
        200: '#EAE8E3',
        300: '#DDD9D4',
    },
    neutral: {
        50: '#F9FAFB',
        100: '#F3F4F6',
        150: '#A5AAB3',
        200: '#E5E7EB',
        250: '#515A69',
        300: '#D1D5DB',
        350: '#2B3544',
        400: '#9CA3AF',
        500: '#6B7280',
        600: '#4B5563',
        700: '#374151',
        800: '#1F2937',
        900: '#111827',
        white: '#FFFFFF',
        black: '#121212',
        transparent: 'transparent',
        brown: '#A07E6D',
        purple: '#B995FF',
        green: '#D7FFDB',
        get DEFAULT() {
            return this[700];
        },
    },
    green: {
        50: '#ECFDF5',
        100: '#D1FAE5',
        200: '#A7F3D0',
        300: '#6EE7B7',
        400: '#34D399',
        500: '#10B981',
        600: '#059669',
        700: '#047857',
        800: '#065F46',
        900: '#064E3B',
        get DEFAULT() {
            return this[500];
        },
    },
    yellow: {
        50: '#FEF5E7',
        100: '#FCE1B3',
        200: '#FAD28F',
        300: '#F8BE5C',
        400: '#F7B13C',
        500: '#F59E0B',
        600: '#DF900A',
        700: '#AE7008',
        800: '#875706',
        900: '#674205',
        get DEFAULT() {
            return this[500];
        },
    },
    red: {
        50: '#FCE9E9',
        100: '#F4BCBC',
        200: '#EF9B9B',
        300: '#E86E6E',
        400: '#E35151',
        500: '#DC2626',
        600: '#C82323',
        700: '#9C1B1B',
        800: '#791515',
        900: '#5C1010',
        get DEFAULT() {
            return this[500];
        },
    },
    malachite: {
        50: '#E6FAEB',
        100: '#B0EFC0',
        200: '#8AE8A2',
        300: '#54DD78',
        400: '#33D65D',
        500: '#00CC35',
        600: '#00BA30',
        700: '#009126',
        800: '#00701D',
        900: '#005616',
        get DEFAULT() {
            return this[500];
        },
    },
    oppo_green: {
        50: '#EAFFF1',
        100: '#BEFFD4',
        200: '#9EFFBF',
        300: '#72FFA1',
        400: '#56FF8F',
        500: '#2CFF73',
        600: '#28E869',
        700: '#1FB552',
        800: '#188C3F',
        900: '#126B30',
        get DEFAULT() {
            return this[500];
        },
    },
    zinc: {
        50: '#ECECED',
        100: '#C3C3C6',
        200: '#A7A7AA',
        300: '#7E7E83',
        400: '#65656B',
        500: '#3F3F46',
        600: '#393940',
        700: '#2D2D32',
        800: '#232327',
        900: '#1A1A1D',
        get DEFAULT() {
            return this[500];
        },
    },
};
export const LETTER_SPACING = {
    tighter: '-0.3px',
    tight: '-0.2px',
    normal: '0px',
    loose: '0.1px',
};
export const LINE_HEIGHT = {
    sm: '12px',
    md: '16px',
    lg: '18px',
    '2lg': '20px',
    xl: '24px',
    '2xl': '32px',
    '3xl': '40px',
};
export const FONT_SIZE = {
    xs: ['10px', '12px'],
    sm: ['12px', '16px'],
    md: ['12px', '20px'],
    base: ['14px', '20px'],
    lg: ['16px', '24px'],
    xl: ['18px', '28px'],
    '2xl': ['20px', '28px'],
    '3xl': ['24px', '32px'],
    '4xl': ['30px', '36px'],
    '5xl': ['36px', '40px'],
    '6xl': ['48px', '64px'],
    '7xl': ['60px', '72px'],
    '8xl': ['72px', '96px'],
    '9xl': ['96px', '128px'],
    '10xl': ['128px', '160px'],
};
export const FONT_FAMILY = {
    sans: ['var(--font-oppo)'],
    'pwa-default': ['var(--font-pwa-default_font)', 'var(--font-oppo)'],
    'pwa-heading': ['var(--font-pwa-heading_font)', 'var(--font-oppo)'],
};

export const SPACING = {
    'space-50': '2px',
    'space-100': '4px',
    'space-150': '6px',
    'space-200': '8px',
    'space-300': '12px',
    'space-400': '16px',
    'space-500': '20px',
    'space-600': '24px',
    'space-800': '32px',
    'space-1000': '40px',
    'space-1200': '48px',
    'space-1600': '64px',
    'space-2000': '80px',
    'space-2400': '96px',
    'space-3200': '128px',
};

export const ZINDEX = {
    'backdrop-loader': 1300,
    'scroll-to-top': 1099,
    toast: 1202,
    'backdrop-dialog': 1200,
    dialog: 1201,
};

export default {};
